<template>
  <div>
    <b-card class="mt-2">
      <b-row>
        <b-col
          cols="12"
          md="3">
          <SearchTextInput
            v-model="searchSubject"
            label="Search"
            placeholder="Subject"
            @keyup.enter.native="fetchFilter()" />
        </b-col>
        <b-col
          cols="12"
          md="3">
          <FilterDropdown
            v-model="searchPriority"
            label="Priority"
            placeholder="Search Priority"
            label-option="label"
            return-value="value"
            :options="priorityOption"
            @input="fetchFilter()" />
        </b-col>
        <b-col
          cols="12"
          md="3">
          <DatePickerFilter
            v-model="startDate"
            label="Start Date" />
        </b-col>
        <b-col
          cols="12"
          md="3">
          <DatePickerFilter
            v-model="endDate"
            label="End Date" />
        </b-col>
        <b-col
          sm="12"
          md="4"
          class="mb-1 mt-2 mb-sm-0 d-flex justify-content-end">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="w-100"
            variant="primary"
            @click="fetchFilter()">
            <feather-icon
              icon="SearchIcon"
              class="mr-50 mr-md-0 mr-lg-0 mr-xl-50"
            />
            <span class="align-middle">Search</span>
          </b-button>
        </b-col>
      </b-row>
      <TableTopTicket
        v-model="pagination.limit"
        @change="fetchFilter()" />
      <b-table
        striped
        hover
        responsive
        :items="items"
        :fields="fields"
        @row-clicked="rowClick($event)"
      >
        <template #cell(incidentType)="data">
          <div v-if="data.item.tickeType && data.item.tickeType.name">
            {{ data.item.tickeType.name }}
          </div>
          <div v-else>
            -
          </div>
        </template>
        <template #cell(createdAt)="data">
          <div v-if="data.item.createdAt">
            {{ data.item.createdAt | formatDateTimeEn }}
          </div>
          <div v-else>
            -
          </div>
        </template>
        <template #cell(createdBy)="data">
          <div v-if="data.item && data.item.createdBy ">
            {{ data.item.createdBy.username }}
          </div>
          <div v-else>
            -
          </div>
        </template>
        <template #cell(progressBy)="{item}">
          <div v-if="item && item.progressBy.firstName ">
            {{ item.progressBy.firstName }} {{ item.progressBy.lastName }}
          </div>
          <div v-else>
            -
          </div>
        </template>
        <template #cell(dueDate)="data">
          <div v-if="data.item.dueDate">
            {{ data.item.dueDate | formatDateTimeEn }}
          </div>
          <div v-else>
            -
          </div>
        </template>
        <template #cell(resolveDate)="data">
          <div v-if="data.item.resolveDate">
            {{ data.item.resolveDate | formatDateTimeEn }}
          </div>
          <div v-else>
            -
          </div>
        </template>
        <template #cell(ticketStatus)="data">
          <b-badge :variant="$ticketStatus(data.value)">
            {{ data.value | formatTicketStatus }}
          </b-badge>
        </template>
      </b-table>
    </b-card>
    <b-col
      cols="12"
      sm="12"
      class="d-flex align-items-center justify-content-center justify-content-sm-end">
      <b-pagination
        v-model="pagination.page"
        :total-rows="pagination.totalDocs"
        :per-page="pagination.limit"
        first-number
        last-number
        class="mb-0 mt-1 mt-sm-0"
        prev-class="prev-item"
        next-class="next-item"
        @change="showCurrentPage($event)"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18" />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18" />
        </template>
      </b-pagination>
    </b-col>
  </div>
</template>

<script>
import TableTopTicket from '@/components/TableTopTicket.vue'
import SearchTextInput from '@/components/Filter/SearchTextInput.vue'
import TicketProvider from '@/resources/TicketProvider'
import FilterDropdown from '@/components/Filter/FilterDropdown.vue'
import DatePickerFilter from '@/components/Filter/DatePickerFilter.vue'

const TicketService = new TicketProvider()

export default {
  components: {
    TableTopTicket,
    FilterDropdown,
    DatePickerFilter,
    SearchTextInput
  },
  data () {
    return {
      isDeleteModalActive: false,
      perPage: 10,
      currentPage: 1,
      totalUsers: 100,
      incidentFilter: null,
      priorityFilter: null,
      statusFilter: null,
      searchPriority: '',
      searchSubject: '',
      startDate: '',
      endDate: '',
      fields: [
        { key: 'ticketStatus', label: 'Status' },
        { key: 'code', label: 'Ticket No.', sortable: false },
        { key: 'ticketCodeRefer', label: 'Refer No.', sortable: false },
        { key: 'incidentType', label: 'Ticket Type' },
        { key: 'subject', label: 'subject' },
        { key: 'priority', label: 'Priority' },
        { key: 'customerName', label: 'CustomerName' },
        { key: 'createdAt', label: 'CreatedAt' },
        { key: 'progressBy', label: 'ProgressBy' },
        { key: 'dueDate', label: 'DueDate', sortable: false },
        { key: 'resolveDate', label: 'ResolveDate', sortable: false }
      ],
      priorityOption: [
        { label: 'Low', value: 'low' },
        { label: 'Medium', value: 'medium' },
        { label: 'High', value: 'high' }
      ],
      items: [],
      pagination: {
        page: 1,
        limit: 10,
        totalDocs: 10
      },
      selectedItem: {},
      initialTicketType: {}
    }
  },
  computed: {
    customerProfileId () {
      return this.$route.params.id
    }
  },
  mounted () {
    this.getTicketByCustomerProfile()
  },
  methods: {
    async getTicketByCustomerProfile () {
      try {
        const data = await TicketService.findByCustomerProfile(this.customerProfileId, this.pagination.page, this.pagination.limit, {
          searchPriority: this.searchPriority,
          searchSubject: this.searchSubject,
          startDate: this.startDate,
          endDate: this.endDate
        })
        this.setPagination(data.page, data.totalDocs)
        this.items = [...data.data]
      } catch (error) {
        console.error(error)
      }
    },
    fetchFilter () {
      this.setPagination(1)
      this.getTicketByCustomerProfile()
    },
    showCurrentPage (page) {
      this.setPagination(page)
      this.getTicketByCustomerProfile()
    },
    setPagination (page, totalDocs) {
      this.pagination.page = page || this.pagination.page
      this.pagination.totalDocs = totalDocs === 0 ? 0 : totalDocs || this.pagination.totalDocs
    },
    rowClick (val) {
      this.$router.push(`/ticket/detail/${val.id ? val.id : null}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.v-select {
  background: white;
}
.card-body {
  padding: 0;
}
::v-deep input.vs__search::placeholder {
  color: #B9B9C3;
}
</style>
